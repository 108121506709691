@import '../../styles/dimensions';
@import '../../styles/utils';
@import '../../styles/screens';

.box {
    display: flex;
    flex-direction: column;

    h1,
    h2 {
        font-weight: 700;
        text-align: center;
    }
}

.box-default {
    composes: pad-vertical from '../../components/box/box.module.scss';
    width: 100%;
}

.box-primary {
    composes: box-primary from '../../components/layout/section-visual/section-visual.module.scss';
}

.box-loading {
    min-width: $section-image-width;
}

.list {
    order: 2;
    padding-top: $gap-medium;
}

.action {
    text-align: center;
}

.action form {
    align-items: flex-start;
}

.subtitle {
    text-align: center;
}

.subtitle::first-letter {
    text-transform: uppercase;
}

@include screen-min-width('medium') {
    .block {
        box-sizing: content-box;
        min-height: $section-image-height + $gap-x-large;
    }

    .box-primary {
        padding: $gap-large 0;
    }

    .list {
        order: 0;
        padding-top: 0;
    }

    .action {
        margin-top: $gap-medium;
    }

    .with-reminder-link {
        padding-bottom: $gap-small;
    }
}

@include screen-max-width('medium') {
    .box {
        h1,
        h2 {
            margin: $gap-small 0;
        }
    }

    .action {
        margin-top: $gap-small;
    }

    .action-fixed {
        background: var(--step-midnight);
        bottom: 0;
        left: 0;
        padding: $gap-medium;
        position: fixed;
        width: 100%;
        z-index: var(--fixed-section-z-index);
    }

    .with-reminder-link {
        padding-bottom: $gap-x-large;
    }
}

@media print {
    .block {
        color: var(--step-dark-text);
        display: inline-block;
        margin-right: 1cm;
        min-height: 10cm;
        padding: 1cm;
        vertical-align: top;
        width: 11cm;
    }
}
