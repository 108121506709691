@import '../../../styles/dimensions';
@import '../../../styles/utils';
@import '../../../styles/screens';

.box-default {
    composes: pad-vertical from '../../box/box.module.scss';
}

.visual {
    max-width: calc(100vw - #{$gap-large * 2});
    position: relative;
}

@include screen-max-width('medium') {
    .fade {
        margin-bottom: $gap-x-large;
        mask-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 1) 50%,
            rgba(0, 0, 0, 0) 100%
        );
    }
}

@media print {
    .visual {
        display: inline-block;
        vertical-align: bottom;
        width: 2cm;
    }
}
