@import '../../../styles/dimensions';
@import '../../../styles/colors';
@import '../../../styles/screens';
@import '../../../styles/utils';

.block {
    display: flex;
    flex-direction: column;
}

@supports (display: grid) {
    @include screen-min-width('medium') {
        .left {
            grid-area: medium-first;
        }

        .right {
            grid-area: medium-second;
        }
    }

    @include screen-max-width('medium') {
        .top {
            grid-area: small-first;
            margin-top: $gap-large;
        }

        .bottom {
            grid-area: small-second;
        }
    }
}

@include justify-variability;
@include align-variability;
@include text-align-variability;

@media print {
    .block {
        display: inline-block;
        text-align: left;
    }
}
